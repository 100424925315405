/* You can add global styles to this file, and also import other style files */
@import url('./assets/fonts/fonts.css')
@import 'assets/scss/material-overwrites/main'

:root
  --color-error-text: #ef5151
  --color-background-button: #28b2e8
  --color-background-form: #2d344d

* 
  margin: 0
  padding: 0
  box-sizing: border-box

body
  margin: 0
  font-family: hn-heavy

.hidden
  visibility: hidden

.custom-scroll::-webkit-scrollbar-track
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3)
  border-radius: 8px
  background-color: #F5F5F5

.custom-scroll::-webkit-scrollbar
  width: 5px
  background-color: #F5F5F5

.custom-scroll::-webkit-scrollbar-thumb
  border-radius: 8px
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3)
  background-color: #555