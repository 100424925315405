.mat-mdc-select-arrow {
  color: var(--color-background-button);
}

.mat-mdc-select-trigger {
  .mat-mdc-select-arrow-wrapper {
    height: auto;
  }
}

.mat-mdc-option {
  --mdc-typography-body1-font-size: 14px;
  --mdc-typography-body1-letter-spacing: normal;
}