.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #8e8e8ee2;
}

.mat-mdc-form-field, .mat-mdc-floating-label {
  --mdc-typography-body1-letter-spacing: normal;
  --mdc-typography-body1-line-height: 18px;
  --mdc-typography-body1-font-size: 14px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 18px;
  left: 0;
}

.mat-mdc-form-field-infix {
  min-height: auto;
}

.mat-mdc-form-field-subscript-wrapper {
  .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
    padding: 0;

    .mat-mdc-form-field-error {
      font-size: 9px !important;
    }
  }
}


.mdc-text-field--disabled .mdc-notched-outline__leading, .mdc-text-field--disabled .mdc-notched-outline__trailing,
.mdc-text-field--disabled .mdc-notched-outline__notch {
  border-color: #0000001f;
}

.mat-mdc-form-field-subscript-wrapper, .mat-mdc-form-field-bottom-align::before {
  --mdc-typography-caption-line-height: 12px;
}

.mat-mdc-form-field {
  width: 100%;
  .mat-mdc-form-field-bottom-align::before {
    height: auto;
  }
}

.mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--outlined {
  background-color: var(--mdc-field-background-color, white) !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: var(--mdc-field-background-color, white) !important;
}

@supports (top: max(0%)) {
  .mdc-text-field--outlined {
    padding-right: max(0.75em, var(--mdc-shape-small, 4px)) !important;
    padding-left: max(0.75em, calc(var(--mdc-shape-small, 4px) + 4px)) !important;
  }
}

.mdc-text-field:not(.mdc-text-field--invalid):not(.mdc-text-field--focused):not(.mdc-text-field--disabled) {
  .mdc-notched-outline > * {
    border-color: #0000001f;
  }
}

.mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  max-width: 200%;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translate(4px, -28.75px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
}

.mdc-notched-outline__notch {
  width: fit-content !important;
  min-width: fit-content;
  padding: 0 8px 0 0;
  white-space: nowrap;
  overflow: visible;
}

.mdc-floating-label {
  white-space: nowrap;
  overflow: visible;
  text-overflow: clip;
  max-width: 100%;
}