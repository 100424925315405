.mat-mdc-card-content:first-child {
  padding-top: 0 !important;
}

.mdc-card__actions {
  align-items: normal !important;
}

.mat-mdc-card-content {
  padding: 0 !important;
}