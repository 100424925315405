.mat-mdc-dialog-container {
  --mdc-dialog-subhead-size: 1rem;
  --mdc-dialog-subhead-line-height: 28px;
  --mdc-dialog-supporting-text-line-height: 18px;
  --mdc-dialog-supporting-text-size: 14px;
  --mdc-dialog-supporting-text-tracking: normal:
}

.mdc-dialog .mdc-dialog__content {
  padding: var(--mdc-dialog-content-padding, 0.5rem 1rem 0.5rem 1rem) !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  margin: 0;
}

.mdc-dialog__title {
  margin: 0 !important;
}

.mdc-dialog__title::before {
  content: none !important;
}