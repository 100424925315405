.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: white;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: white;
}

.mat-mdc-checkbox label {
  text-transform: uppercase;
  font-family: hn-heavy, sans-serif;
  letter-spacing: normal;
}