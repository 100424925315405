@use "@angular/material" as mat;

@include mat.core();
@include mat.all-component-typographies();

// Define tu paleta de colores personalizada
$gca-palette: ( 50: #e5f6fc, 100: #bfe8f8, 200: #94d9f4, 300: #69c9ef, 400: #48beeb, 500: #28b2e8, 600: #24abe5, 700: #1ea2e2, 800: #1899de, 900: #0f8ad8, A100: #ffffff, A200: #d2ecff, A400: #9fd6ff, A700: #85cbff, contrast: (50: #000000, 100: #000000, 200: #000000, 300: #000000, 400: #000000, 500: #000000, 600: #000000, 700: #000000, 800: #ffffff, 900: #ffffff, A100: #000000, A200: #000000, A400: #000000, A700: #000000,),);
$gca-primary: mat.define-palette($gca-palette, 600);

$gca-palette-accent: (50: #e6e7ea,100: #c0c2ca,200: #969aa6,300: #6c7182,400: #4d5268,500: #2d344d,600: #282f46,700: #22273d,800: #1c2134,900: #111525,A100: #6683ff,A200: #3359ff,A400: #0030ff,A700: #002be6,contrast: (50: #000000,100: #000000,200: #000000,300: #ffffff,400: #ffffff,500: #ffffff,600: #ffffff,700: #ffffff,800: #ffffff,900: #ffffff,A100: #000000,A200: #ffffff,A400: #ffffff,A700: #ffffff,),);
$gca-accent: mat.define-palette($gca-palette-accent, 200);

$gca-palette-warn: (50: #f9e0e0,100: #f0b3b3,200: #e68080,300: #db4d4d,400: #d42626,500: #cc0000,600: #c70000,700: #c00000,800: #b90000,900: #ad0000,A100: #ffd7d7,A200: #ffa4a4,A400: #ff7171,A700: #ff5858,contrast: (50: #000000,100: #000000,200: #000000,300: #ffffff,400: #ffffff,500: #ffffff,600: #ffffff,700: #ffffff,800: #ffffff,900: #ffffff,A100: #000000,A200: #000000,A400: #000000,A700: #000000,),);
$gca-warn: mat.define-palette($gca-palette-warn, 400);

// Define tu tema personalizado
$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $gca-primary,
      accent: $gca-accent,
      warn: $gca-warn,
    ),
    density: -3,
  )
);

// Aplica tu tema personalizado
@include mat.all-component-themes($my-theme);

.mat-body, .mat-body-2, .mat-typography .mat-body, .mat-typography .mat-body-2, .mat-typography {
  letter-spacing: normal;
}
